import _ from 'lodash'
import axios from '../utils/axios';
import Model from './lib/Model';

class Producer extends Model {
  static async enroll(form) {
    const formData = new FormData();
    const pickedForm = _.pick({...form}, [
      'agent',
      'agentEmail',
      'agentTel',
      'postalCode',
      'address1',
      'address2',
      'fax',
      'accountBank',
      'accountHolder',
      'accountNumber',
      'businessDoc',
      'accountDoc'
    ])
    Object.entries(pickedForm).map(([key, value]) => formData.append(key, value))
    const response = await axios.post('/v1/partner/enrollment', formData);
    const {error, result} = response.data;
    if (error) {
      throw new Error(error)
    }

    return result.producer;
  }

  constructor(raw) {
    super(raw);
    this.name = raw.name;
    this.businessNumber = raw.businessNumber;
    this.agent = raw.agent;
    this.email = raw.email;
    this.tel = raw.tel;
    this.postalCode = raw.postalCode;
    this.address1 = raw.address1;
    this.address2 = raw.address2;
    this.accountBank = raw.accountBank;
    this.accountNumber = raw.accountNumber;
    this.accountHolder = raw.accountHolder;
    this.isGranted = raw.isGranted;
    this.ownerName = raw.ownerName;
  }
}

export default Producer;
