import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import User from 'models/User';
import Producer from 'models/Producer';
import Load from 'pages/load';
import axios, {authApi} from 'utils/axios';

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    producer: null,
    temp: false
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        if (!accessToken.startsWith('Bearer'))
            accessToken = `Bearer ${accessToken}`;
        localStorage.setItem('authorization', accessToken);
        axios.defaults.headers.common.Authorization = `${accessToken}`;
        authApi.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
        localStorage.removeItem('authorization');
        delete axios.defaults.headers.common.Authorization;
        delete authApi.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {isInitialised, isAuthenticated, user, producer, temp} = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised,
                user: user ? new User(user) : null,
                producer: producer ? new Producer(producer) : null,
                temp
            };
        }
        case 'LOGIN': {
            const {user, temp} = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user: new User(user),
                temp
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                producer: null,
                temp: false
            };
        }
        case 'PASSWORD_RESET': {
            const {temp} = action.payload;
            return {
                ...state,
                isAuthenticated: false,
                temp
            }
        }
        case 'PASSWORD_CHANGE': {
            const {temp} = action.payload;
            return {
                ...state,
                isAuthenticated: false,
                temp
            }
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    login: () => Promise.resolve(),
    logout: () => {
    },
    passwordReset: () => Promise.resolve(),
    passwordChange: () => Promise.resolve()
});

function isValidPassword(password) {
    const validRegex = /[a-zA-Z0-9!@$_\-~]{7,}/;
    return password?.length > 7 && validRegex.test(password);
}

function isConfirmedPassword(password, confirm) {
    return isValidPassword(password) && password === confirm;
}


export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const verify = async () => {
        try {
            const authorization = window.localStorage.getItem('authorization');
            if (authorization && isValidToken(authorization)) {
                setSession(authorization);
                const response = await axios.get('/v1/partner/producer/');
                const {result} = response.data;
                const {producer, user} = result;
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isInitialised: true,
                        isAuthenticated: !!user,
                        producer,
                        user,
                        temp: user ? user.isTemp : false
                    }
                });
            } else {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isInitialised: true,
                        isAuthenticated: false,
                        producer: null,
                        user: null,
                    }
                });
            }
        } catch (err) {
            // if (err.response.status === 403) {
            //   alert(err.response.data.error);
            //   logout();
            // }
            const producer = err.response?.data?.result?.producer;
            const user = err.response?.data?.result?.user;
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isInitialised: true,
                    isAuthenticated: !!user,
                    producer,
                    user,
                }
            });

        }
    };

    const login = async (id, password) => {
        const response = await axios.post('/v1/login', {id, password});
        const {accessToken, user} = response.data;
        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
        await verify();
    };

    const logout = () => {
        setSession(null);
        dispatch({type: 'LOGOUT'});
    };

    const passwordReset = async (registerNumber) => {
        const response = await authApi.put('/v1/password/reset', {registerNumber})
        if (response.status === 200) {
            dispatch({
                type: 'PASSWORD_RESET',
                payload: {
                    temp: true
                }
            })
        }
        return response.data;
    }

    const passwordChange = async (password, passwordConfirm) => {
        const response = await authApi.put('/v1/password/change', {password, passwordConfirm})
        if (response.status === 200) {
            dispatch({
                type: 'PASSWORD_CHANGE',
                payload: {
                    temp: false
                }
            })
        }
        return response.data;
    }

    useEffect(() => {
        verify().catch(console.error);
    }, [state?.user?.id]);

    if (!state.isInitialised) {
        return <Load/>;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                passwordReset,
                passwordChange
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
