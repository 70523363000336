import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    img: {
        width: 180,
        margin: theme.spacing(2, 0)
    },
    text:{
        fontWeight:'bold'
    }
}));

const Load = () => {
    const classes = useStyles();
    return (
        <Box width={'100vw'} height={'100vh'} display={'flex'}
             flexDirection={'column'}
             justifyContent={'center'} alignItems={'center'}>
            <img className={classes.img} src={'load.png'}/>
            <Typography className={classes.text} variant={'body1'} color={'primary'} align={'center'}>
                로딩 중...
            </Typography>
        </Box>
    )
}

export default Load;
