import React, { lazy } from 'react';
import MainLayout from 'layouts/MainLayout';
import CloseLayout from 'layouts/CloseLayout';
import PlainLayout from 'layouts/PlainLayout';

export default [
  {
    title: 'B#good',
    path: '/register',
    exact: true,
    guard: false,
    layout: PlainLayout,
    page: lazy(() => import('../pages/register'))
  },
  {
    title: 'B#good 파트너',
    path: '/login',
    exact: true,
    guard: false,
    layout: PlainLayout,
    page: lazy(() => import('../pages/login'))
  },
  {
    title: 'B#good 파트너',
    path: '/order/:id',
    exact: true,
    layout: CloseLayout,
    page: lazy(() => import('../pages/order'))
  },
  {
    title: 'B#good 파트너',
    path: '/stock/:id',
    exact: true,
    layout: CloseLayout,
    page: lazy(() => import('../pages/stock'))
  },
  {
    title: 'B#good 파트너',
    path: '/orders',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/orders')),
  },
  {
    title: 'B#good 파트너',
    path: '/balances',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/balances')),
  },
  {
    title: 'B#good 파트너',
    path: '/',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/main')),
  },
  {
    title: 'B#good 파트너',
    path: '/enrollment',
    exact: true,
    layout: PlainLayout,
    page: lazy(() => import('../pages/enrollment'))
  },
  {
    title: 'B#good 파트너',
    path: '/password',
    layout: PlainLayout,
    page: lazy(() => import('../pages/passwordChange'))
  }
];
