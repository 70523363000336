import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import {Link as _Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block'
    }
}));
const Link = forwardRef(({className, children, force, path, type, ...props}, ref) => {
    const classes = useStyles();
    if (type === 'link') {
        return (
            <MuiLink
                className={className}
                classes={classes}
                component={force ? 'a' : _Link}
                href={path}
                to={path}
                underline={'none'}
                ref={ref}>
                {children}
            </MuiLink>
        )
    }
    return (
        <Button className={className} classes={classes} component={_Link} to={path} underline={'none'} ref={ref} {...props}>
            {children}
        </Button>
    )
});


Link.propTypes = {
    type: PropTypes.oneOf(['button', 'link'])
};

Link.defaultProps = {
    type: 'button',
    path: '/'
}

export default Link;
