import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';

const Header = ({title,}) => {
    return (
        <Helmet>
            <title>{title}</title>
            {/*<meta name="description" content="Helmet application"/>*/}
        </Helmet>
    )
}

Header.propTypes = {
    title: PropTypes.string
}

export default Header;
