import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import useAuth from 'hooks/useAuth';
import ErrorBoundary from './ErrorBoundary';
import ScrollToTop from './ScrollToTop';


const PrivateRoute = ({ layout: Layout, component: Component, path, guard, ...props }) => {
  const { user, producer, temp, logout } = useAuth();
  const { pathname } = useLocation();

  if (guard) {
    if (!user && pathname !== '/login')
      return <Redirect to={'/login'}/>;
    if (!producer && pathname !== '/enrollment')
      return <Redirect to={'/enrollment'}/>;
    if (temp && pathname !== '/password' && pathname !== '/login')
      return <Redirect to={'/password'} />
    if (producer && !producer.isGranted && pathname !== '/login'){
      alert('관리자 가입 승인이 필요합니다.')
      logout()
    }
  }

  const renderComponent = () => (
    <ScrollToTop>
      <Layout {...props}>
        <Component/>
      </Layout>
    </ScrollToTop>
  );
  return (
    <ErrorBoundary error={<p>Page has a error</p>}>
      <Route
        exact
        path={path}
        render={renderComponent}/>
    </ErrorBoundary>
  );
};


export default PrivateRoute;

PrivateRoute.defaultProps = {
  guard: true
};