import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Header from 'components/Header';
import TopBar from './components/TopBar';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        flexShrink: 0,
        backgroundColor:theme.palette.background.inner
    }
}));

const MainLayout = ({children, ...props}) => {
    const classes = useStyles();
    return (
        <Box className={classes.layout}>
            <Header {...props}/>
            <TopBar {...props}/>
            <Container className={classes.container} maxWidth={'md'}>
                {children}
            </Container>
        </Box>
    )
}

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default MainLayout;
