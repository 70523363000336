import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import PrivateRoute from './components/PrivateRoute';
import routes from './config/routes';
import NoPage from './pages/404';
import Load from './pages/load';

import AuthContext, { AuthProvider } from 'contexts/JWTAuthContext';

export default function App() {
  return (
    <Suspense fallback={<Load/>}>
      <AuthProvider>
        <SnackbarProvider>
          <Router>
            <Switch>
              {
                routes.map(({ path, layout, page, exact, ...route }, idx) => {
                  return <PrivateRoute
                    key={path}
                    layout={layout}
                    path={path}
                    component={page}
                    exact={exact}
                    {...route}
                  />;
                })
              }
              <Route path={'*'} component={NoPage}/>
            </Switch>
          </Router>
        </SnackbarProvider>
      </AuthProvider>
    </Suspense>
  );
}
