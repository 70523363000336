import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#173147',
            light: 'rgba(60,112,156,0.7)',
            contrastText: '#fff'
        },
        secondary: {
            main: '#00b946',
            light: 'rgb(116,207,148)',
            contrastText: '#fff'
        },
        error: {
            main: red.A400,
            contrastText: '#fff'
        },
        background: {
            default: '#fff',
            // inner: '#fff'
            inner: '#f9f9f9'
        },
        text: {
            primary: '#333',
            secondary: '#666',
            disabled: '#777',
            hint: '#777'
        }
    },
});
theme.zIndex = {
    top: 1,
    bar: 9,
    modal: 10
};
theme.overrides = {
    MuiAppBar: {
        root: {
            zIndex: theme.zIndex.bar,
            // backgroundColor: 'transparent'
        }
    },
    MuiContainer: {
        root: {
            paddingLeft: 0,
            paddingRight: 0,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    MuiPaper: {
        root: {},
        rounded: {
            borderRadius: 8
        }
    },
    MuiAvatar: {
        colorDefault: {
            backgroundColor: '#ececec'
        },
        rounded: {
            borderRadius: 8
        }
    },
    MuiButton: {
        root: {
            padding: theme.spacing(1, 2)
        }
    },
    MuiOutlinedInput: {
        input: {
            padding: theme.spacing(2, 2)
        }
    },
    MuiFormHelperText: {
        contained: {
            marginLeft: 0,
        }
    },
    MuiFormControlLabel: {
        root: {
            marginRight: 0
        }
    }
}
export default theme;
