import React from 'react';
import _ from 'lodash'
import axios from '../utils/axios'
import Model from './lib/Model';

class User extends Model {

  static async register(form) {
    const { password, passwordConfirm } = form;
    if (password !== passwordConfirm) throw new Error('Please check password');
    const pickedForm = _.pick({
      ...form
    }, [
      'registerNumber',
      'password',
      'passwordConfirm',
      'name',
      'email',
      'tel',
      'ownerName',
      'businessType',
      'businessSector',
      'postalCode',
      'address1',
      'address2',
      'isIndividual'
    ]);
    const response = await axios.post(`/v1/register`, pickedForm);
    const { error, result } = response.data;
    if (error) {
      throw new Error(error);
    }
    return result;
  }

  static async isExists(id) {
    if (!id) throw new Error('Id for checking is required');
    const response = await axios.post(`/v1/register/exists`, {
      registerNumber: id
    });
    const { error, result } = response.data;
    if (error) {
      throw new Error(error);
    }
    return result.isExists;
  }

  constructor(raw) {
    super(raw);
    this.registerNumber = raw.registerNumber;
    this.name = raw.name;
    this.email = raw.email;
    this.tel = raw.tel;
    this.postalCode = raw.postalCode;
    this.address1 = raw.address1;
    this.address2 = raw.address2;
    this.isTemp = raw.isTemp;
    this.isIndividual = raw.isIndividual;
    this.ownerName = raw.ownerName;
  }
}

export default User;