import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import CloseLayout from 'layouts/CloseLayout';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        marginTop: theme.spacing(-8)
    },
    img: {
        width: 100,
        margin: theme.spacing(2, 0)
    },
    text:{
        fontWeight:'bold'
    }

}));

const NoPage = () => {
    const classes = useStyles();
    return (
        <CloseLayout>
            <Box className={classes.container}>
                <img className={classes.img} src={'404.png'}/>
                <Typography className={classes.text}  variant={'body1'} color={'primary'} align={'center'}>
                    해당 내용을 찾을 수 없습니다 (404)
                </Typography>
            </Box>
        </CloseLayout>
    )
}

export default NoPage;
