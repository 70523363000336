import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Header from 'components/Header';
import CloseBar from './components/CloseBar';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        flexShrink: 0,
        backgroundColor:theme.palette.background.inner
    }
}));

const CloseLayout = ({children, ...props}) => {
    const classes = useStyles();

    return (
        <Box className={classes.layout}>
            <Header {...props}/>
            <CloseBar {...props}/>
            <Container className={classes.container} maxWidth={'md'}>
                {children}
            </Container>

        </Box>
    )

}

export default CloseLayout;
