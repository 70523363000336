import React from 'react';
import Link from './Link';

const  LinkHomeLogo = ({className})=>{
    return(
        <Link path={'/'}>
            <img
                className={className}
                alt="Bgood"
                src="/logo.png"
            />
        </Link>
    )
}

export default LinkHomeLogo;
