import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useHistory} from 'react-router';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Close from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';

import LinkHomeLogo from 'components/LinkHomeLogo';


const useStyles = makeStyles(theme => ({
    logo: {
        height: 28
    },
    toolBar: {
        justifyContent: 'space-between'
    },
    close: {
        marginRight: -12
    }
}));

const CloseBar = ({className}) => {
    const classes = useStyles();
    const history = useHistory();
    const onClose = () => {
        history.goBack();
    };
    return (
        <AppBar
            className={clsx(className)}
            color={'inherit'}
            position={'sticky'}
            elevation={0}
        >
            <Toolbar className={classes.toolBar}>
                <LinkHomeLogo className={classes.logo}/>
                <IconButton className={classes.close} onClick={onClose}>
                    <Close/>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default CloseBar
