import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    layout: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        flexShrink: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
    }
}));

const PlainLayout = ({children, ...props}) => {
    const classes = useStyles();
    return (
        <Box className={classes.layout}>
            <Container className={classes.container} maxWidth={'md'}>
                {children}
            </Container>
        </Box>
    )
}

PlainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default PlainLayout;
